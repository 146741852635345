export class StringTool {
    public static contains(letters: string, source: string){
        if(source == null) return false;
        for(let i = 0; i < letters.length; i++){
            if(source.indexOf(letters.charAt(i)) >= 0) return true;
        }
        return false;
    }

    public static containsLowerCaseLetters(source: string){
        return StringTool.contains("abcdefghijklmnopqrstuvwxyzöäü", source);
    }

    public static containsUpperCaseLetters(source: string){
        return StringTool.contains("abcdefghijklmnopqrstuvwxyzöäü".toLocaleUpperCase(), source);
    }

    public static containsDigits(source: string){
        return StringTool.contains("0123456789", source);
    }

    public static checkPassword(p: string): string {
        if(p == null || p.length == 0) return "Das Passwort ist leer.";
        if(p.length < 8) return "Das Passwort ist zu kurz (mindestens 8 Zeichen)";
        if(!StringTool.containsLowerCaseLetters(p)) return "Das Passwort enthält keine Kleinbuchstaben.";
        if(!StringTool.containsUpperCaseLetters(p)) return "Das Passwort enthält keine Großbuchstaben.";
        if(!StringTool.containsDigits(p)) return "Das Passwort enthält keine Ziffern.";

        return null;

    }

    public static format2Dez(n: number): string {
        return (Math.round(n * 100) / 100).toFixed(2).replace(".", ",");
    }

}